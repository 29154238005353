<template>
    <div class="comment-slide bg-[#f6f6f6] py-14">
        <div :source="source" class="container m-auto text-center xl:max-w-[1200px]">
            <div ref="splideRef" class="splide w-full">
                <div class="splide__track">
                    <ul class="splide__list">
                        <li v-for="(comments, i) in commentsList" :key="i" class="splide__slide">
                            <div class="grid grid-cols-2 gap-4 px-4">
                                <div v-for="(comment, j) in comments" :key="i + '-' + j" class="comment-border h-full rounded-md bg-white p-8 text-left" :class="{ 'col-span-1': span1(i, j, comments.length), 'col-span-2': span2(i, j, comments.length) }">
                                    <div class="flex justify-between">
                                        <div class="flex flex-col gap-1">
                                            <span class="text-[14px] font-bold">{{ comment.author }}</span>
                                            <p class="text-[12px] text-[#FF8247]">Verifed Purchase</p>
                                            <div class="dasiy-rating dasiy-rating-sm">
                                                <input type="radio" name="rating-2" class="dasiy-mask dasiy-mask-star-2 bg-[#FF31E4]" />
                                                <input type="radio" name="rating-2" class="dasiy-mask dasiy-mask-star-2 bg-[#FF31E4]" />
                                                <input type="radio" name="rating-2" class="dasiy-mask dasiy-mask-star-2 bg-[#FF31E4]" />
                                                <input type="radio" name="rating-2" class="dasiy-mask dasiy-mask-star-2 bg-[#FF31E4]" />
                                                <input type="radio" name="rating-2" class="dasiy-mask dasiy-mask-star-2 bg-[#FF31E4]" checked />
                                            </div>
                                        </div>
                                        <div>
                                            <img class="w-[120px]" :src="`${ossPath}${comment.platform}`" alt="amazon" />
                                        </div>
                                    </div>
                                    <h4 class="my-4 text-[14px] font-semibold">
                                        {{ comment.title }}
                                    </h4>
                                    <p class="text-small text-[14px]">{{ comment.content }}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
// https://vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script
// declare additional options
export default {
    inheritAttrs: false,
};
</script>
<script setup lang="ts">
import chunk from "lodash/chunk";
import Splide from "@splidejs/splide";

const props = defineProps<{ source: any }>();

const commentsList = ref<any>([]);
const splideRef = ref();

function initSwiper() {
    new Splide(splideRef.value, {
        type: "loop",
        pagination: false,
        speed: 600,
        classes: {
            arrows: "splide__arrows comment-splite-arrows",
            arrow: "splide__arrow comment-splite-arrow",
            prev: "splide__arrow--prev comment-splite-prev",
            next: "splide__arrow--next comment-splite-next",

            pagination: "splide__pagination comment-splite-pagination",
            page: "splide__pagination__page comment-splite-page",
        },
    }).mount();
}

let w: number;
onMounted(async () => {
    w = document.body.clientWidth;
    if (w > 1024) {
        commentsList.value = chunk(props.source.list, props.source.chunkNum || 1);
    } else {
        commentsList.value = chunk(props.source.list, 1);
    }

    await nextTick();

    setTimeout(() => {
        initSwiper();
    }, 0);
});

const span1 = (i: number, j: number, length: number) => {
    return (i % 2 === 0 && j !== length - 1) || (i % 2 === 1 && j !== 0) || props.source.chunkNum === 2;
};

const span2 = (i: number, j: number, length: number) => {
    return !((i % 2 === 0 && j !== length - 1) || (i % 2 === 1 && j !== 0)) && (props.source.chunkNum !== 2 || w < 1024);
};
</script>

<style scoped lang="scss">
.comment-slide {
    :global(.comment-splite-arrow) {
        background: rgba(255, 49, 228, 0.12);
        width: 42px;
        height: 42px;
    }
    :global(.comment-splite-arrow svg) {
        fill: rgba(255, 49, 228, 1);
    }

    :global(.comment-splite-prev) {
        left: -54px;
    }

    :global(.comment-splite-next) {
        right: -54px;
    }

    @media screen and (max-width: 1024px) {
        :global(.comment-splite-prev) {
            left: 10px;
        }

        :global(.comment-splite-next) {
            right: 10px;
        }
    }
}
.comment-border {
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
</style>
